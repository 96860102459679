/** @jsx jsx */
import { jsx, Container, Flex, Slider, Box, Text } from 'theme-ui';
import React, { useMemo } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import useMedia from 'use-media';

import { useOTF } from '../../woff-provider/use-otf';
import { useFontLoader } from '../../woff-provider/use-font-loader';
// import { Stack, Row } from '../grid';
import { TesterRow } from './tester-row';

const baseSizes = {
  xs: 7,
  sm: 18,
  md: 26,
  lg: 32,
  xl: 48,
  xxl: 68,
  xxxl: 88,
};

export const TesterPresets = observer((props) => {
  const { font } = props;
  const otf = useOTF(font._id);
  useFontLoader(font._id);

  const store = useLocalStore(() => {
    const presetDefault = props.presets[0];
    return {
      font,
      fontSize: baseSizes[presetDefault.fontSize],
      presets: props.presets,
      activePresetIndex: 0,
      activePreset: presetDefault,
      activeFeatures: presetDefault.feature.reduce((c, f) => {
        c[f.key] = 'off';
        return c;
      }, {}),
    };
  });

  return <TesterRow store={store} />;
});

export default TesterPresets;
