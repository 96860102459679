/** @jsx jsx */
import React from 'react';
import { Button, Text, jsx } from 'theme-ui';
import * as Dialog from '@radix-ui/react-dialog';
import { BlockContent } from '../block-content';
import { Stack } from '../grid';
import { Anchor } from '../link';

const StyledOverlay = (props) => {
  return (
    <Dialog.Overlay
      {...props}
      sx={{
        backgroundColor: 'bg.3',
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      }}
    />
  );
};

const StyledContent = (props) => {
  return (
    <Dialog.Content
      {...props}
      sx={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 200,
        maxWidth: ['90%', '80%', '50rem'],
        width: '100%',
        height: '100%',
        maxHeight: '85vh',
        backgroundColor: 'bg.0',
        color: 'txt.0',
        borderRadius: 0,
        '&:focus': {
          outline: 'none',
        },
      }}
    />
  );
};

const ContentInner = (props) => (
  <div
    sx={{
      position: ' absolute',
      top: [8, 11],
      bottom: [8, 11],
      overflow: 'scroll',
      width: '100%',
      px: 10,
      margin: 0,
    }}
    {...props}
  />
);

export const LanguagesModal = ({ languages, tally = 'All' }) => {
  return (
    <Dialog.Root>
      <Dialog.Trigger
        as={Button}
        variant="small_link"
        sx={{ color: ['txt.1'], borderRadius: 0, p: 0, mr: 5 }}
      >
        {tally} languages
      </Dialog.Trigger>
      <StyledOverlay sx={{ zIndex: 999 }} />
      <StyledContent sx={{ zIndex: 1000 }}>
        <ContentInner>
          <Stack variant="1">
            <Text as="h4" variant="section_header">
              {tally} languages
            </Text>
            <BlockContent blocks={languages} measure={false} />
          </Stack>
        </ContentInner>
        <Dialog.Close
          as={Button}
          variant="link"
          sx={{
            color: ['txt.1'],
            borderRadius: 0,
            position: 'absolute',
            top: 5,
            right: 5,
          }}
        >
          Close
        </Dialog.Close>
      </StyledContent>
    </Dialog.Root>
  );
};
