/** @jsx jsx */
import { Container, Text, jsx } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';

import { Grid, Col, Row, Stack } from '../grid';
import { BlockContent } from '../block-content';

import { Product } from './product';

export const TypefaceFreeShop = ({ typeface, data }) => {
  const { settings } = useStaticQuery(settingsQuery);

  const product = data.product;

  return (
    <div id="free-licence">
      <Container
        sx={{
          borderBottom: 'line',
          borderColor: 'line',
        }}
      >
        <Row>
          <Stack variant="0">
            <Text as="h4" variant="section_header" id="shop">
              {data.shopTitle || 'Free for personal use'}
            </Text>
            <Grid columns={[1, 12]}>
              <Col span={[1, 8, 8, 8, 6]}>
                <BlockContent blocks={data.shopInfo || settings.freebie} />
              </Col>
            </Grid>

            <Product
              key={`${product.slug.current}`}
              licenceKey="personal"
              product={product}
              typeface={typeface}
            />
          </Stack>
        </Row>
      </Container>
    </div>
  );
};

const settingsQuery = graphql`
  query {
    settings: sanityShopSettings {
      vat
      buying: _rawBuying(resolveReferences: { maxDepth: 10 })
      freebie: _rawFreebie(resolveReferences: { maxDepth: 10 })
    }
  }
`;
