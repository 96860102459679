/** @jsx jsx */
import React, { useMemo } from 'react';
import { Text, Flex, Box, jsx } from 'theme-ui';

import { TesterPresets } from '../tester-presets';
import { TesterCharset } from '../tester-charset';

const Comps = {
  testerPresets: TesterPresets,
  testerCharset: TesterCharset,
};

export const BlocksContent = ({ blocks }) => {
  return (
    <>
      {blocks.map((block, idx) => {
        const Comp = Comps[block._type];
        return <Comp key={block._key} {...block} />;
      })}
    </>
  );
};
