/** @jsx jsx */
import React, { useMemo } from 'react';
import { Flex, Container, Text, jsx } from 'theme-ui';
import { BlockContent } from '../block-content';
import { Award } from '../../icons';

import { Grid, Row, Col, Stack } from '../grid';
import { LanguagesModal } from './languages-modal';

const Dt = (props) => {
  return <Text as="dt" variant="small" sx={{ color: 'txt.3' }} {...props} />;
};

const Dd = (props) => {
  return <Text as="dd" sx={{ color: 'txt.0', mb: [7] }} {...props} />;
};

export const NurseryInfo = ({ data }) => {
  return (
    <Container
      sx={{
        borderBottom: 'line',
        borderColor: 'line',
      }}
    >
      <Row>
        <Stack variant="0">
          <Text as="h4" variant="section_header">
            Font Details
          </Text>
          <Grid columns={[1, 12]} gap={true} gapY={true}>
            <Col span={[1, 8, 8, 8, 6]}>
              <Stack variant="0">
                <BlockContent blocks={data.about} />
                <Stack variant="1">
                  {data.awards.map((award) => (
                    <Flex key={award.title}>
                      <div sx={{ flex: '0 auto', width: '30px', mt: 2, mr: 8 }}>
                        <Award />
                      </div>
                      <Text sx={{ maxWidth: '35ch', lineHeight: 1.4 }}>
                        <b>{award.title}</b>
                        <br />
                        {award.description}
                      </Text>
                    </Flex>
                  ))}
                </Stack>
              </Stack>
            </Col>
            <Col span={[1, 4, 4, 4, 6]}>
              <Stack variant="1">
                <dl>
                  {data.released ? (
                    <>
                      <Dt>Released</Dt>
                      <Dd>{data.released}</Dd>{' '}
                    </>
                  ) : null}

                  {data.credits.map(({ title, description }) => (
                    <React.Fragment key={title}>
                      <Dt>{title}</Dt>
                      <Dd>{description.join(', ')}</Dd>
                    </React.Fragment>
                  ))}

                  {data.scripts ? (
                    <>
                      <Dt>Script Support </Dt>
                      <Dd>
                        {data.scripts} <br />
                        <LanguagesModal languages={data.languages} tally={data.languages_tally} />
                      </Dd>
                    </>
                  ) : null}

                  {data.styles ? (
                    <>
                      <Dt>Styles</Dt>
                      <Dd>{data.styles}</Dd>{' '}
                    </>
                  ) : null}

                  {data.formats ? (
                    <>
                      <Dt>Available Formats</Dt>
                      <Dd>{data.formats}</Dd>{' '}
                    </>
                  ) : null}
                </dl>
              </Stack>
            </Col>
          </Grid>
        </Stack>
      </Row>
    </Container>
  );
};
