/** @jsx jsx */
import React, { useMemo } from 'react';
import { Container, Text, Flex, Box, jsx } from 'theme-ui';
import { TextF5 } from '../text-f5';
import { Anchor } from '../link';
import { Grid, Row, Col, Stack } from '../grid';

export const NurseryHero = ({ eyebrow, title, font, buyCta, freeCta, specimen, hasFreebie }) => {
  return (
    <header>
      <Container
        sx={{
          backgroundColor: ['bg.0'],
        }}
      >
        <Row>
          <Stack variant="0">
            <Stack gap={[8]}>
              <Text as="h1" variant="section_header" sx={{ opacity: 0.5 }}>
                {eyebrow}
              </Text>
              <TextF5 as="p" className="type" variant="f5_typeface_tagline" font={font._id}>
                {title}
              </TextF5>
            </Stack>
            <Flex sx={{ flexDirection: ['column', 'column', 'row'], mt: 6, gap: 2 }}>
              {hasFreebie && (
                <Anchor to="#free-licence" variant="buttons.primary">
                  {freeCta}
                </Anchor>
              )}
              <Anchor to="#shop" variant={hasFreebie ? 'buttons.ghost' : 'buttons.primary'}>
                {buyCta}
              </Anchor>
              {specimen ? (
                <Anchor href={specimen.asset.url} variant="buttons.ghost" external>
                  PDF Specimen
                </Anchor>
              ) : null}
            </Flex>
          </Stack>
        </Row>
      </Container>
    </header>
  );
};
