/** @jsx jsx */
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Text, Flex, jsx } from 'theme-ui';

import { TextF5 } from '../text-f5';
import { useCart } from '../../cart-provider';

import { Stack } from '../grid';
import { IconAddRemove } from '../icon-add-remove';

const ProductButton = ({ selected, isBig, ...props }) => (
  <button
    {...props}
    sx={{
      textAlign: 'left',
      position: 'relative',
      bg: selected ? 'accent' : 'bg.1',
      color: selected ? 'txt.4' : 'text',
      transition: 'background-color 0.3s cubic-bezier(0.190, 1.000, 0.220, 1.000)',

      pt: isBig ? [6, 7, 8] : [4, 5, 6],
      pb: isBig ? [6, 7, 8] : [4, 5, 6],
      px: [6, 7, 8],
      display: 'flex',
      flexDirection: ['column', 'row'],
      justifyContent: ['flex-start'],
      alignItems: [null, 'baseline'],
      '& .vat, & .subtitle': {
        opacity: selected ? 1 : 0.6,
      },
      '&:hover': {
        color: selected ? 'txt.4' : 'accent',
        '.price': {
          color: selected ? 'txt.4' : 'text',
        },
        '.vat, .subtitle,': {
          opacity: 1,
          color: 'text',
        },
      },
    }}
  />
);

export const Product = (props) => {
  const { typeface, product, licenceKey } = props;
  const [selected, setSelected] = useState(false);
  const { title, subtitle, slug, description, isFamily, font } = product;

  const guid = product.guid;
  const productID = `${slug.current}-${licenceKey}`;

  const { cart, ready } = useCart();

  const isInCart = cart[productID];

  const categories = useMemo(() => {
    const familyOrStyleCategory = isFamily ? 'family' : 'style';
    const arrayOfCategories = [
      slug.current,
      typeface,
      licenceKey,
      familyOrStyleCategory,
      `${typeface}-${familyOrStyleCategory}`,
      `${typeface}-${licenceKey}`,
      `${typeface}-${familyOrStyleCategory}-${licenceKey}`,
    ];

    return arrayOfCategories;
  }, [typeface, licenceKey, isFamily]);

  useEffect(() => {
    setSelected(isInCart);
  }, [isInCart]);

  const displayPrice = 0;

  const options = [{ name: '1 User, 10,000 Views/Month', priceModifier: 0 }];

  const removeFromCart = useCallback(async () => {
    try {
      const state = await window.Snipcart.store.getState();
      const item = state.cart.items.items.find((i) => i.id === productID);
      if (!item) return;

      await window.Snipcart.api.cart.items.remove(item.uniqueId);
    } catch (error) {}
  }, [productID]);

  const addToCart = useCallback(async () => {
    try {
      const option = options[0];

      const data = {
        id: productID,
        url: '/products',
        basePrice: 0,
        categories: categories,
        name: `${title} (${licenceKey})`,
        quantity: 1,
        maxQuantity: 1,
        taxable: true,
        shippable: false,
        stackable: false,
        fileGuid: guid.trim(),
        customFields: [
          {
            displayValue: option.name,
            operation: option.priceModifier,
            name: 'Licence',
            options: options,
            optionsArray: options.map((p) => p.name),
            required: true,
            type: 'dropdown',
            value: option.name,
          },
        ],
      };

      await window.Snipcart.api.cart.items.add(data);
    } catch (error) {
      console.log(error);
    }
  }, [productID, options]);

  const buttonProps = !ready
    ? {
        onClick: (e) => {
          e.preventDefault();
          return false;
        },
      }
    : selected
    ? {
        onClick: () => {
          if (isInCart) {
            setSelected(false);
            removeFromCart();
          }
        },
      }
    : {
        onClick: (e) => {
          if (!isInCart) {
            setSelected(true);
            addToCart();
          }
        },
      };

  return (
    <>
      <Stack gap={4}>
        <ProductButton
          id={`font_${slug.current}`}
          key={`button_${slug.current}`}
          selected={selected}
          isBig
          {...buttonProps}
        >
          <Stack gap={4} sx={{ flex: 1, pt: 4 }}>
            {font ? (
              <TextF5 variant="f5_typeface_product" font={font._id} sx={{ flex: 1 }}>
                {title}
              </TextF5>
            ) : (
              <Text>{title} ERROR</Text>
            )}
            {subtitle &&
              subtitle.map((value, idx) => (
                <Text key={`${idx}-${value}`} variant="small" className="subtitle">
                  {value}
                </Text>
              ))}
          </Stack>
          <Flex sx={{ flex: '0 auto', alignItems: 'center' }}>
            <Flex
              sx={{
                flex: 1,
                alignItems: 'center',
                mt: [8, 0],
                width: '100%',
                position: 'relative',
              }}
            >
              <Text as="span" className="price" variant="h3" sx={{ mr: [6, 8], flex: '0 auto' }}>
                £{displayPrice}
              </Text>
            </Flex>
            <div
              sx={{
                width: [9, 10],
                ml: [0, 0],
                position: ['absolute', 'relative'],
                right: [6, 'auto'],
                top: [8, 'auto'],
              }}
            >
              <IconAddRemove selected={selected} />
            </div>
          </Flex>
        </ProductButton>
      </Stack>
    </>
  );
};
