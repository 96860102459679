/** @jsx jsx */
import React, { useMemo } from 'react';
import { graphql } from 'gatsby';

import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/seo';
import { PromoBanner } from '../components/promo-banner';
import { NurseryInfo } from '../components/nursery-info';

import { Row } from '../components/grid';
import { jsx, Text, Container } from 'theme-ui';
import { NurseryHero } from '../components/nursery-hero';
import { BlocksContent } from '../components/blocks-content';
import { TypefaceFeatures } from '../components/typeface-features';
import { TypefaceShop } from '../components/typeface-shop';
import { TypefaceFreeShop } from '../components/typeface-free-shop';

export const query = graphql`
  query NurseryTemplateQuery($id: String!) {
    typeface: sanityNursery(_id: { eq: $id }) {
      ...SanityNurseryFragment
    }
  }
`;

const PageNurseryTemplate = (props) => {
  const { data, errors } = props;

  const typeface = data && data.typeface;

  const hasFreebie = data.typeface.freebie;

  return (
    <>
      {errors && <SEO title="GraphQL Error" />}
      {errors && <GraphQLErrorList errors={errors} />}
      {typeface && <SEO {...typeface.seo} />}
      {typeface.promoSlotTop && <PromoBanner {...typeface.promoSlotTop} />}
      <NurseryHero {...typeface.hero} specimen={typeface.info.specimen} hasFreebie={hasFreebie} />
      <NurseryInfo data={typeface.info} />
      <BlocksContent blocks={typeface.blocks} />
      {typeface.features && typeface.features.rows.length > 0 && (
        <TypefaceFeatures data={typeface.features} families={typeface.families} />
      )}
      {hasFreebie && <TypefaceFreeShop typeface={typeface.slug.current} data={typeface.freebie} />}
      <TypefaceShop typeface={typeface.slug.current} data={typeface.products} />
    </>
  );
};

export default PageNurseryTemplate;
