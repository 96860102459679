/** @jsx jsx */
import { jsx, Flex, Container, Box, Slider, Text } from 'theme-ui';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { Grid, Row, Col, Stack } from '../grid';

import { VisuallyHidden } from '../visually-hidden';
import React, { useState, useCallback, useEffect, useRef } from 'react';

const baseSizes = {
  xs: 7,
  sm: 18,
  md: 26,
  lg: 32,
  xl: 48,
  xxl: 68,
  xxxl: 88,
};

export const TesterRow = observer(({ store }) => {
  const { align, sample, rtl, feature } = store.activePreset;
  const ref = useRef(null);

  const [enabled, setEnabled] = useState(false);

  const handlePaste = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    var plaintext = e.clipboardData.getData('text/plain');
    document.execCommand('inserttext', false, plaintext);
  }, []);

  const onFontSizeChange = useCallback(
    (e) => {
      store.fontSize = e.target.value;
    },
    [store.activePreset]
  );

  const fontFeatureSettings = Object.keys(store.activeFeatures)
    .reduce((res, cur) => {
      if (store.activeFeatures[cur] === 'off') return res;

      res.push(`"${cur}" ${store.activeFeatures[cur]}`);
      return res;
    }, [])
    .join(',');

  return (
    <Box
      ref={ref}
      sx={{
        borderBottom: '1px solid',
        borderColor: 'line',
        position: 'relative',
        '&:focus': {
          outline: 'none',
        },
        '&:focus-visible': {
          outline: '1px solid',
          outlineColor: 'accent',
          outlineOffset: '0',
        },
      }}
      tabIndex={0}
    >
      <Box
        sx={{
          position: 'relative',
        }}
      >
        <div
          sx={{
            position: 'absolute',
            top: 7,
            left: 7,
          }}
        >
          {Object.keys(store.activeFeatures).map((key) => (
            <button
              key={key}
              sx={{
                fontSize: 1,
                color: store.activeFeatures[key] === 'on' ? 'accent' : 'txt.3',
              }}
              onClick={() =>
                (store.activeFeatures[key] = store.activeFeatures[key] === 'on' ? 'off' : 'on')
              }
            >
              {key}
            </button>
          ))}
        </div>
        <Flex
          sx={{
            position: 'absolute',
            bottom: 7,
            left: '50%',
            transform: 'translateX(-50%)',
            gap: 5,
          }}
        >
          {store.presets.length > 1
            ? store.presets.map((_, i) => (
                <button
                  aria-label={`Preset ${i + 1}`}
                  sx={{
                    width: '1rem',
                    height: '1rem',
                    borderRadius: '50%',
                    backgroundColor: i === store.activePresetIndex ? 'accent' : 'transparent',
                    border: '1px solid',
                    borderColor: 'accent',
                    cursor: 'pointer',
                    ':hover': {
                      backgroundColor: 'accent',
                    },
                  }}
                  onClick={(e) => {
                    store.fontSize = baseSizes[store.presets[i].fontSize];
                    store.activePresetIndex = i;
                    store.activePreset = store.presets[i];
                    store.activeFeatures = store.presets[i].feature.reduce((c, f) => {
                      c[f.key] = 'off';
                      return c;
                    }, {});
                  }}
                />
              ))
            : null}
        </Flex>
        <Box sx={{ position: 'absolute', top: 7, right: 7 }}>
          <Slider
            id={`size-`}
            name={`size-`}
            min="1"
            max="100"
            step="1"
            value={store.fontSize}
            onChange={onFontSizeChange}
            aria-label="Font Size"
            sx={{
              width: ['100px', null, '180px'],
              margin: 0,
            }}
          />
        </Box>

        <Container sx={{ paddingTop: [16, 16, 17], paddingBottom: [16, 16, 17] }}>
          <div
            dir={rtl ? 'rtl' : 'ltr'}
            className={`txt-${store.font._id.trim().replace(/-/g, '')}`}
            sx={{
              fontFamily: `"${store.font._id}"`,
              outline: 'none',
              opacity: 0,
              lineHeight: 'normal',
              fontVariantLigatures: 'no-common-ligatures',
              textAlign: 'center',
              fontFeatureSettings: fontFeatureSettings,
              fontSize: [
                `calc(10px + ${store.fontSize * 0.3}vmin)`,
                `calc(10px + ${store.fontSize * 0.25}vmax)`,
              ],
              direction: rtl ? 'rtl' : 'ltr',
            }}
            lang={sample ? sample.script.code : 'en'}
            contentEditable={true}
            suppressContentEditableWarning={true}
            spellCheck={false}
            onPaste={handlePaste}
          >
            {sample.sample}
          </div>
        </Container>
      </Box>
    </Box>
  );
});

export default TesterRow;
