/** @jsx jsx */
import React, { useState, useCallback, useEffect, useRef } from 'react';
import { jsx, Text, Container, Slider, Box } from 'theme-ui';
import { TextF5 } from '../text-f5';
import { observer, useLocalStore } from 'mobx-react';
import { Grid, Row, Col, Stack } from '../grid';

import { useFontLoader } from '../../woff-provider/use-font-loader';

const gridSizes = {
  '10': [6, 16, 20],
  '20': [16],
  '60': [14],
  '40': [12],
  '50': [10],
  '60': [8],
  '70': [6],
  '80': [4],
  '90': [2],
};

export const TesterCharset = observer((props) => {
  const { font } = props;
  useFontLoader(font._id);

  const store = useLocalStore(() => {
    return {
      font,
      grid: [1, 2, 3, 4],
      fontSize: 32,
    };
  });

  const onFontSizeChange = useCallback(
    (e) => {
      store.fontSize = e.target.value;

      const gridSize = Object.keys(gridSizes).reduce((prev, curr) => {
        return curr < e.target.value ? curr : prev;
      });

      store.grid = gridSizes[gridSize];
    },
    [store]
  );

  return (
    <Box
      sx={{
        borderBottom: '1px solid',
        borderColor: 'line',
        position: 'relative',
        '&:focus': {
          outline: 'none',
        },
        '&:focus-visible': {
          outline: '1px solid',
          outlineColor: 'accent',
          outlineOffset: '0',
        },
      }}
      tabIndex={0}
    >
      <Container
        sx={{ position: 'relative', paddingTop: [16, 16, 17], paddingBottom: [16, 16, 17] }}
      >
        <Box sx={{ position: 'absolute', top: 7, right: 7, gap: 5 }}>
          <Slider
            id={`size-`}
            name={`size-`}
            min="1"
            max="100"
            step="1"
            value={store.fontSize}
            onChange={onFontSizeChange}
            aria-label="Font Size"
            sx={{
              width: ['100px', null, '180px'],
              margin: 0,
            }}
          />
        </Box>
        <div
          sx={{
            display: 'grid',
            gridTemplateColumns: `repeat(auto-fit, minmax(${store.fontSize * 3.5}px, 1fr))`,
            textAlign: 'center',
          }}
        >
          {props.charset.characters.map((char) => (
            <div
              sx={{
                textAlign: 'center',
              }}
            >
              <TextF5
                as="span"
                sx={{
                  lineHeight: 1,
                  fontSize: [`calc(20px + ${store.fontSize * 0.2}vmin)`],
                }}
                font={font._id}
                lang={props.charset.script ? props.charset.script.code : ''}
              >
                {char}
              </TextF5>
            </div>
          ))}
        </div>
      </Container>
    </Box>
  );
});

export default TesterCharset;

{
  /* <div
            dir={rtl ? 'rtl' : 'ltr'}
            className={`txt-${store.font._id.trim().replace(/-/g, '')}`}
            sx={{
              fontFamily: `"${store.font._id}"`,
              outline: 'none',
              opacity: 0,
              lineHeight: 'normal',
              fontVariantLigatures: 'no-common-ligatures',
              textAlign: 'center',
              // fontFeatureSettings: fontFeatureSettings,
              fontSize: [
                `calc(10px + ${store.fontSize * 0.3}vmin)`,
                `calc(10px + ${store.fontSize * 0.25}vmax)`,
              ],
              direction: rtl ? 'rtl' : 'ltr',
            }}
            lang={sample ? sample.script.code : 'en'}
            contentEditable={true}
            suppressContentEditableWarning={true}
            spellCheck={false}
            onPaste={handlePaste}
          >
            {sample.sample}
          </div> */
}
